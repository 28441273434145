type BreadcrumbType = {
  name: string
  link: string
}[]

export const CODING_FOR_KIDS_ITEMS: BreadcrumbType = [
  {
    name: 'Codingal',
    link: '/',
  },
  {
    name: 'Coding for kids',
    link: '/coding-for-kids/',
  },
]

const PYTHON_FOR_KIDS: BreadcrumbType = [
  {
    name: 'Codingal',
    link: '/',
  },
  {
    name: 'Courses',
    link: 'https://www.codingal.com/courses/',
  },
  {
    name: 'Python for kids',
    link: '/courses/python-for-kids/',
  },
]

const SCRATCH_PROGRAMMING: BreadcrumbType = [
  {
    name: 'Codingal',
    link: '/',
  },
  {
    name: 'Courses',
    link: 'https://www.codingal.com/courses/',
  },
  {
    name: 'Scratch programming',
    link: '/courses/scratch-programming/',
  },
]
const ROBLOX: BreadcrumbType = [
  {
    name: 'Codingal',
    link: '/',
  },
  {
    name: 'Courses',
    link: 'https://www.codingal.com/courses/',
  },
  {
    name: 'Roblox',
    link: '/courses/roblox/',
  },
]
const AP_COMPUTER_SCIENCE_A: BreadcrumbType = [
  {
    name: 'Codingal',
    link: '/',
  },
  {
    name: 'Courses',
    link: 'https://www.codingal.com/courses/',
  },
  {
    name: 'AP Computer Science A',
    link: '/courses/ap-computer-science-a/',
  },
]
const HPE_CODEWARS_CODE_BATTLE: BreadcrumbType = [
  {
    name: 'Codingal',
    link: '/',
  },
  {
    name: 'Courses',
    link: 'https://www.codingal.com/courses/',
  },
  {
    name: 'HPE CodeWars Code Battle',
    link: '/courses/hpe-codewars-code-battle/',
  },
]
const HPE_CODEWARS_HACKATHON: BreadcrumbType = [
  {
    name: 'Codingal',
    link: '/',
  },
  {
    name: 'Courses',
    link: 'https://www.codingal.com/courses/',
  },
  {
    name: 'HPE CodeWars Hackathon',
    link: '/courses/hpe-codewars-hackathon/',
  },
]
const AI_AND_ML: BreadcrumbType = [
  {
    name: 'Codingal',
    link: '/',
  },
  {
    name: 'Courses',
    link: 'https://www.codingal.com/courses/',
  },
  {
    name: 'AI and Machine Learning',
    link: '/courses/artificial-intelligence-and-machine-learning/',
  },
]

export const COURSES_BREADCRUMB = {
  PYTHON_FOR_KIDS,
  SCRATCH_PROGRAMMING,
  ROBLOX,
  AP_COMPUTER_SCIENCE_A,
  HPE_CODEWARS_CODE_BATTLE,
  HPE_CODEWARS_HACKATHON,
  AI_AND_ML,
}
